import api from "./api";
import globalLit from "./globalLit";

const userInfo = JSON.parse(localStorage.getItem('userinfo') || '{}');
console.log("userInfotrx => ", userInfo);

export default {
  add(data) {
    return api.execute(`post`, `/moderator/services`, data);
  },
  update(data) {
    return api.execute(`put`, `/moderator/services`, data);
  },
  get(payload) {
    let queryString = "";
    for (let key in payload) {
      if (payload[key]) {
        queryString += `&${key}=${payload[key]}`;
      }
    }
    // return api.execute("get", `/moderator/services?${queryString}`);
    return api.execute(`get`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/services?${queryString}`);
        
  },
  remove(data) {
    return api.execute(`delete`, `/moderator/services`, data);
  },
  getMonthlyServices(data) {
    let payload = globalLit.checkCountry(data);
    return api.execute(`post`, `/list/services/monthly`, payload);
  },
  addServiceMapping(data) {
    return api.execute(`post`, `/moderator/smap`, data);
  },
  getServiceMapping(payload = {}) {
    const queryString = new URLSearchParams(payload).toString();
    return api.execute(`get`, `/moderator/smap?${queryString}`);
  },

  deleteServiceMapping(payload) {
    return api.execute(`delete`, `/moderator/smap`, payload);
  },
  editServiceMapping(data) {
    return api.execute(`put`, `/moderator/smap`, data);
  },

  getAdNetworks(data) {
    data = globalLit.checkCountry(data);
    return api.execute(`post`, `/service/adnetworks`, data);
  },

  getChennel(data) {
    data = globalLit.checkCountry(data);
    return api.execute(`post`, `/service/channel`, data);
  },

  getAdNetworksByDate(data) {
    data = globalLit.checkCountry(data);
    return api.execute(`post`, `/service/adnetworks/days`, data);
  },

  getDestUrls(data) {
    data = globalLit.checkCountry(data);
    return api.execute(`post`, `/service/desturl`, data);
  },
  getUniqueHosts(data) {
    data = globalLit.checkCountry(data);
    return api.execute(`post`, `/service/hosts`, data);
  },

  getUniqueUrls(data) {
    data = globalLit.checkCountry(data);
    return api.execute(`post`, `/service/urls`, data);
  },

  getServiceInfo(data) {
    return api.execute(`post`, `/serviceinfo`, data);
  },
};
