<template>
  <!-- Page Header Start-->
  <b-container fluid>
    <b-row>
      <div class="page-main-header mt-0" :class="{ open: !togglesidebar }">
        <div class="main-header-right row">
          <div
            class="mobile-sidebar d-block"
            v-if="layout.settings.sidebar.type != 'horizontal_sidebar'"
          >
            <div class="media-body text-right switch-sm">
              <label class="switch" title="Hide">
                <span @click="toggle_sidebar">
                  <feather type="menu"></feather>
                </span>
                <!-- <input id="sidebar-toggle" type="checkbox" checked="checked"><span class="switch-state" @click="toggle_sidebar"></span> -->
              </label>
            </div>
          </div>
          <div class="main-header-left d-lg-none">
            <div class="logo-wrapper">
              <router-link to="/">
                <img src="../assets/images/webpatrol-navbar-logo.png" alt="" />
              </router-link>
            </div>
          </div>
          <div class="vertical-mobile-sidebar">
            <i class="fa fa-bars sidebar-bar"></i>
          </div>
          <!-- nav-right col-md-7 left-menu-header -->
          <div class="nav-right col-8 m-1">
            <ul class="nav-menus-left" :class="{ open: mobiletoggle1 }">
              <!-- <li><a class="text-dark" href="javascript:void(0)" @click="toggle_fullscreen()" title="Fullscreen"><feather type="maximize"></feather></a></li> -->
              <!-- {{ $route.meta.title }} -->
              <!-- col-md-4 mr-2 -->
              <li class="custom-font-20 breadcrumbHeader p-0">
                <!-- <Breadcrumbs  :title="$route.meta.title" class="showBreadcrumbs"/> -->
                <ol class="breadcrumb">
                  <li class="breadcrumb-item" v-if="user_role == 'manager'">
                    <router-link
                      class="text-dark"
                      :to="{ path: '/manager/users' }"
                      >Home</router-link
                    >
                  </li>
                  <li class="breadcrumb-item" v-else>
                    <router-link :to="{ path: '/home' }" class="text-dark"
                      >Home</router-link
                    >
                  </li>
                  <li class="breadcrumb-item active">
                    {{ $route.meta.title }}
                  </li>
                </ol>
              </li>
              <li class="col-md-3 text-left" v-if="user.role != 'manager'">
                <!-- <b-select v-model="selectedCountry" :options="user.countryArray"></b-select> -->
                <b-form-select
                  class="custom-font-20"
                  style="border-color: #e3e3e3"
                  value-field="id"
                  text-field="name"
                  v-model="selectedCountry"
                  :options="countryArray"
                  @change="StoreCountry()"
                ></b-form-select>
              </li>
              <li class="col-md-4">
                <div
                  class="search-container position-relative"
                  ref="dropdownContainer"
                  @click.stop
                >
                  <!-- Search Input -->
                  <b-input-group>
                    <b-input-group-prepend>
                      <span class="input-group-text search-bg custom-font-18">
                        <i class="icofont icofont-search-alt-2"></i>
                      </span>
                    </b-input-group-prepend>
                    <b-form-input
                      class="border-left-0 custom-font-18"
                      type="text"
                      v-model="searchQuery"
                      @focus="showDropdown = true"
                      @input="filterList"
                      placeholder="Search Services"
                    ></b-form-input>
                  </b-input-group>
                  <!-- Search Results List -->
                  <div
                    v-if="showDropdown"
                    class="dropdown-list position-absolute bg-white border rounded shadow-sm"
                  >
                    <ul class="list-unstyled mb-0">
                      <li
                        v-for="(item, index) in filteredList"
                        :key="index"
                        class="dropdown-item cursor-pointer"
                        @click="redirectToPage(item)"
                      >
                        <span title="Provider">{{
                          sentenceCase(item.provider_name)
                        }}</span>
                        -
                        <span title="Service">{{
                          sentenceCase(item.title)
                        }}</span>
                      </li>
                      <li
                        v-if="!filteredList.length"
                        class="dropdown-item text-muted"
                      >
                        No results found.
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              
              <!-- <li class="col-md-2">
                <filter-view  />
              </li> -->
              
              <li class="col-md-2">
                <filter-view v-if="!hideFilterView" />
              </li>


              <!-- <li>
                <div class="dropdown">
                  <b-dropdown id="dropdown-1" >
                    <template v-slot:button-content>
                      Category <span class="mx-2 badge badge-pill badge-primary">3</span>
                    </template>
                    <b-dropdown-item>Admin</b-dropdown-item>
                    <b-dropdown-item>Shopify</b-dropdown-item>
                    <b-dropdown-item>Ecommerce</b-dropdown-item>
                    <b-dropdown-item>Prestashop</b-dropdown-item>
                  </b-dropdown>
                </div>
              </li> -->
              <!-- <li>
                <div class="dropdown">
                  <b-dropdown id="dropdown-1" class="status-dropdown">
                    <template v-slot:button-content>
                      Status <span class="mx-2 badge badge-pill badge-primary">7</span>
                    </template>
                      <b-dropdown-item>
                        <h6 class="mb-0">Ongoing Projects</h6>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <p class="mb-0">HTML5 Validation Report</p>
                        <div class="progress sm-progress-bar">
                          <div class="progress-bar bg-primary" role="progressbar" style="width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <p class="mb-0">Bootstrap Admin Templates</p>
                        <div class="progress sm-progress-bar">
                          <div class="progress-bar bg-secondary" role="progressbar" style="width: 60%" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <p class="mb-0">Goggle Crome Extension</p>
                        <div class="progress sm-progress-bar">
                          <div class="progress-bar bg-success" role="progressbar" style="width: 45%" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <p class="mb-0">Clients Projects</p>
                        <div class="progress sm-progress-bar">
                          <div class="progress-bar bg-info" role="progressbar" style="width: 70%" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </b-dropdown-item>
                  </b-dropdown>
                </div>
              </li> -->
              <!-- <li>
                <div class="dropdown">
                  <b-dropdown id="dropdown-1">
                    <template v-slot:button-content>
                      order <span class="mx-2 badge badge-pill badge-primary">2</span>
                    </template>
                    <b-dropdown-item>Paypal</b-dropdown-item>
                    <b-dropdown-item>Credit Card</b-dropdown-item>
                    <b-dropdown-item>Visa</b-dropdown-item>
                    <b-dropdown-item>Paytm</b-dropdown-item>
                  </b-dropdown>
                </div>
              </li> -->
            </ul>
            <!-- <div class="d-xl-none mobile-toggle-left pull-right" @click="openmobilemenu1"><feather type="more-horizontal"></feather> <i data-feather="more-horizontal"></i></div> -->
          </div>
          <div class="nav-right col-3 pull-right right-menu m-2 p-0">
            <ul class="nav-menus">
              <!-- <li>
                <form class="form-inline search-form"><span class="mobile-search search-icon" @click="search_open()"><i class="fa fa-search"></i></span></form>
              </li> -->
              <li>
                <div class="dropdown">
                  <b-dropdown id="dropdown-1" class="profile-dropdown">
                    <template v-slot:button-content>
                      <button
                        class="btn dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                      >
                        <span class="media user-header">
                          <img
                            class="mr-2 mt-1 rounded-circle img-40"
                            src="../assets/images/dashboard/user.png"
                            alt=""
                          />
                          <div class="media-body">
                            <span class="custom-font-16 f-w-600">{{
                              user.email
                            }}</span
                            ><br />
                            <span class="d-block custom-font-16">{{
                              user.role
                            }}</span>
                          </div>
                        </span>
                      </button>
                    </template>
                    <!-- <b-dropdown-item class="gradient-primary-1">
                        <h6 class="mb-0">{{user.email}}</h6><span>{{user.role}}</span>
                    </b-dropdown-item> -->
                    <!-- <b-dropdown-item class="text-left"><feather type="user"></feather>Profile</b-dropdown-item> -->
                    <!-- <b-dropdown-item><feather type="message-square"></feather>Inbox</b-dropdown-item>
                    <b-dropdown-item><feather type="file-text"></feather>Taskboard</b-dropdown-item>
                    <b-dropdown-item><feather type="settings"></feather>Settings</b-dropdown-item> -->
                    <b-dropdown-item @click="logout()"
                      ><feather type="log-out"></feather>Logout</b-dropdown-item
                    >
                  </b-dropdown>
                </div>
              </li>
              <!-- <li>
                <div class="dropdown">
                  <b-dropdown id="dropdown-1" class="notification-dropdown">
                    <template v-slot:button-content>
                      <button class="btn dropdown-toggle" type="button" data-toggle="dropdown">
                    <feather type="bell"></feather><span class="notification badge badge-pill badge-danger f-10">2</span></button>
                    </template>
                    <b-dropdown-item class="gradient-primary-1">
                        <h6>Notifications</h6><span>You have 6 unread messages</span>
                    </b-dropdown-item>
                    <b-dropdown-text>
                      <div class="media">
                          <div class="notification-icons bg-success mr-3"><feather type="thumbs-up"></feather></div>
                          <div class="media-body">
                            <h6>Someone Likes Your Posts</h6>
                            <p class="mb-0"> 2 Hours Ago</p>
                          </div>
                        </div>
                    </b-dropdown-text>
                    <b-dropdown-text class="pt-0">
                      <div class="media">
                          <div class="notification-icons bg-info mr-3"><feather type="message-circle"></feather></div>
                          <div class="media-body">
                            <h6>3 New Comments</h6>
                            <p class="mb-0"> 1 Hours Ago</p>
                          </div>
                        </div>
                    </b-dropdown-text>
                    <b-dropdown-text class="bg-light txt-dark">
                      <a href="#" class="p-0">All </a> notification
                    </b-dropdown-text>
                  </b-dropdown>
                </div>
              </li> -->
              <!-- <li><a class="right_side_toggle" href="javascript:void(0)" @click="toggle_rightsidebar"><feather type="message-square" ></feather></a></li> -->
              <li>
                <!-- <div class="dropdown">
                  <b-dropdown id="dropdown-1" class="droplet-dropdown">
                    <template v-slot:button-content>
                      <button class="btn dropdown-toggle" type="button" data-toggle="dropdown"><feather type="droplet" ></feather></button>
                    </template>
                    <b-dropdown-text class="gradient-primary-1 text-center">
                        <h6>Web Patrol</h6><span>{{user.email}}</span>
                    </b-dropdown-text>
                    <b-dropdown-text>
                      <div class="row">
                          <div class="col-sm-4 col-6 droplet-main"><feather type="file-text" ></feather><span class="d-block">Content</span></div>
                          <div class="col-sm-4 col-6 droplet-main"><feather type="activity" ></feather><span class="d-block">Activity</span></div>
                          <div class="col-sm-4 col-6 droplet-main"><feather type="users" ></feather><span class="d-block">Contacts</span></div>
                          <div class="col-sm-4 col-6 droplet-main"><feather type="clipboard" ></feather><span class="d-block">Reports</span></div>
                          <div class="col-sm-4 col-6 droplet-main"><feather type="anchor" ></feather><span class="d-block">Automation</span></div>
                          <div class="col-sm-4 col-6 droplet-main"><feather type="settings" ></feather><span class="d-block">Settings</span></div>
                        </div>
                    </b-dropdown-text>
                    <b-dropdown-text class="text-center">
                      <b-button class="btn btn-primary btn-air-primary" style="background-color:red !important;" @click="logout()">Logout</b-button>
                    </b-dropdown-text>
                  </b-dropdown>
                </div> -->
              </li>
            </ul>
          </div>
          <form class="form-inline search-full" :class="{ open: searchOpen }">
            <div class="form-group form-control-plaintext">
              <input
                :class="{ open: mobile_search }"
                v-on:keyup="searchTerm"
                v-model="terms"
                type="text"
                placeholder="Search.."
              />
              <div
                :class="
                  searchResult ? 'Typeahead-menu is-open' : 'Typeahead-menu'
                "
                v-if="menuItems.length"
              >
                <div
                  class="ProfileCard u-cf"
                  v-for="(menuItem, index) in menuItems.slice(0, 8)"
                  :key="index"
                >
                  <div class="ProfileCard-avatar header-search">
                    <feather :type="menuItem.icon"></feather>
                  </div>
                  <div class="ProfileCard-details">
                    <div class="ProfileCard-realName">
                      <span @click="removeFix()"
                        ><router-link
                          :to="{ path: menuItem.path }"
                          class="realname"
                          >{{ menuItem.title }}</router-link
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                :class="
                  searchResultEmpty
                    ? 'Typeahead-menu is-open'
                    : 'Typeahead-menu'
                "
              >
                <div class="tt-dataset tt-dataset-0">
                  <div class="EmptyMessage">
                    Your search turned up 0 results. Opps There are no result
                    found.
                  </div>
                </div>
              </div>
              <i class="icon-close close-search" @click="search_close()"></i>
            </div>
          </form>
          <div
            class="d-lg-none mobile-toggle pull-right"
            @click="openmobilemenu1"
          >
            <feather type="user"></feather>
            <i data-feather="more-horizontal"></i>
          </div>
        </div>
      </div>
    </b-row>
  </b-container>

  <!-- Page Header Ends -->
</template>
<script>
var body = document.getElementsByTagName("body")[0];
import { mapState } from "vuex";
import UserAuth from "../auth/js/index";
import moment from "moment";
import Multiselect from "vue-multiselect";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import serviceApi from "../../services/ServiceInfo";
import FilterView from "../views/Dashboard/FilterView.vue";
import SubFilter from "../components/SubFilter.vue";
export default {
  name: "Search",
  components: { Multiselect, DateRangePicker, FilterView, SubFilter },
  data() {
    return {
      dateRange: {
        startDate: new Date(),
        endDate: new Date().setMonth(new Date().getMonth() - 2),
      },
      upgrade_modal: false,
      terms: "",
      searchOpen: false,
      searchResult: false,
      searchResultEmpty: false,
      toggle_sidebar_var: false,
      clicked: false,
      toggle_rightsidebar_var: false,
      rightclicked: false,
      mobile_toggle: false,
      mobile_search: false,
      mobiletoggle1: false,
      mobiletoggle2: false,
      user: {},
      filter: {},
      plan_details: {},
      searchQuery: "",
      selectedCountry: null,
      countryArray: [],
      user_role: "",
      itemList: [],
      filteredList: [],
      currentMonth: {},
      lastMonth: {},
      showDropdown: false,
      isBusy: false,
    };
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    this.user_role = this.user.role;
    this.countryArray = [];
    this.user.countryArray.map((item, index) => {
      this.countryArray.push(item);
    });
    if (localStorage.getItem("country")) {
      this.selectedCountry = localStorage.getItem("country");
    } else {
      this.selectedCountry = this.user.countryArray[0].id;
      this.StoreCountry();
    }
    this.init();
    document.addEventListener("click", this.closeDropdown);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeDropdown);
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.searchData,
      layout: (state) => state.layout.layout,
      togglesidebar: (state) => state.menu.togglesidebar,
    }),

    hideFilterView() {
      return this.$route.path.includes("detailedpage") || this.$route.path.includes("InsightDetailedPage") || this.$route.path.includes("viewtrx");
    },
  },
  methods: {

    sentenceCase(str) {
      // Helper function for formatting
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
    async init() {
      // Fetch data from the API
      this.isBusy = true;
      let payload = {};
      try {
        let response = await serviceApi.getMonthlyServices(payload);
        if (response.result) {
          this.currentMonth = response.data.cMonth;
          this.lastMonth = response.data.lMonth;
          const serData = response.data.servicesList;
          const filteredSerData = serData.filter(
            (e) => this.currentMonth[e._id] || this.lastMonth[e._id]
          );
          this.itemList = filteredSerData; // Set itemList
          this.filteredList = [...this.itemList]; // Initialize filteredList
        }
      } catch (e) {
        console.error("Error in fetching monthly services:", e.message);
      } finally {
        this.isBusy = false;
      }
    },
    filterList() {
      this.filteredList = this.itemList.filter(
        (item) =>
          item.provider_name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase()) ||
          item.title.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    redirectToPage(item) {
      const sdate = this.sdate || new Date().toISOString();
      const edate = this.edate || new Date().toISOString();
      const url = this.$router.resolve({
        name: "DetailedPage",
        query: {
          id: item._id,
          sdate,
          edate,
        },
      }).href;
      window.open(url, "_blank");
      this.showDropdown = false;
    },
    closeDropdown(event) {
      if (
        this.$refs.dropdownContainer &&
        !this.$refs.dropdownContainer.contains(event.target)
      ) {
        this.showDropdown = false;
      }
    },
    handleInput() {
      this.$emit("search", this.searchQuery);
    },
    changeDateFormate(d) {
      return moment.utc(d).format("DD/MM/YYYY");
    },
    endAfterStart(start, end) {
      var startDate = new Date(start);
      var endDate = new Date(end);
      return endDate.getTime() >= startDate.getTime();
    },
    toggle_sidebar() {
      this.$store.dispatch("menu/opensidebar");
    },
    openmobilemenu1() {
      this.mobiletoggle1 = !this.mobiletoggle1;
    },
    openmobilemenu2() {
      this.mobiletoggle2 = !this.mobiletoggle2;
    },
    search_open() {
      this.searchOpen = true;
    },
    search_close() {
      this.searchOpen = false;
    },
    searchTerm: function () {
      this.$store.dispatch("menu/searchTerm", this.terms);
    },
    logout: function () {
      // firebase.auth().signOut().then(() => {
      UserAuth.Logout();
      this.$router.replace("/auth/login");
      // })
    },
    addFix() {
      body.classList.add("offcanvas");
      this.searchResult = true;
    },
    removeFix() {
      body.classList.remove("offcanvas");
      this.searchResult = false;
      this.terms = "";
    },
    // toggle_sidebar(){
    //   this.toggle_sidebar_var = !this.toggle_sidebar_var,
    //   this.clicked = !this.toggle_sidebar_var,
    //   this.$emit('clicked',this.clicked)
    // },
    toggle_rightsidebar() {
      (this.toggle_rightsidebar_var = !this.toggle_rightsidebar_var),
        (this.rightclicked = !this.toggle_rightsidebar_var),
        this.$emit("rightclicked", this.rightclicked);
    },
    toggle_fullscreen() {
      if (
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)
      ) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    StoreCountry() {
      // store country in loal storage and reload page
      localStorage.setItem("country", this.selectedCountry);
      location.reload();
    },
  },
  watch: {
    menuItems: function () {
      this.terms ? this.addFix() : this.removeFix();
      if (!this.menuItems.length) this.searchResultEmpty = true;
      else this.searchResultEmpty = false;
    },
  },
};
</script>
<style scoped>
.page-main-header .main-header-right .nav-right > ul > li:before {
  background: none;
}


/* .page-main-header .main-header-right .nav-right .profile-dropdown ul {
  width: unset !important;
} */
@media screen and (min-device-width: 1200px) and (max-device-width: 1366px) {
  .page-main-header
    .main-header-right
    .nav-right.right-menu
    ul
    li
    .user-header
    .media-body {
    display: block !important;
    padding-left: 10px;
  }
}
.search-container {
  max-width: 400px;
  margin: auto;
}
.dropdown-list {
  font-size: 16px !important;
  width: auto;
  min-width: 100%;
  max-height: 400px;
  overflow-y: auto;
  z-index: 1050;
}
.dropdown-item {
  padding: 0.5rem 1rem;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
