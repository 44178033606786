import Vue from "vue";
import Vuex from "vuex";
import 'es6-promise/auto'
import layout from './modules/layout'
import menu from './modules/menu'
import chat from './modules/chat'
import products from './modules/products'
import email from './modules/email'
import todo from './modules/todo'
import jobs from './modules/jobs'
import courses from './modules/courses'

Vue.use(Vuex);

//const debug = process.env.NODE_ENV !== 'production'

export const store = new Vuex.Store({
    modules: {
        layout,
        chat,
        menu,
        products,
        email,
        todo,
        jobs,
        courses
    },
    strict: false,
    
    state: {
        detailedData:JSON.parse(localStorage.getItem("detailedData")) || null,
        payloadData: JSON.parse(localStorage.getItem("payloadData")) || null
    },
    mutations: {
        setDetailedData: (state, payload) => {
            state.detailedData = payload;
            localStorage.setItem("detailedData", JSON.stringify(payload));
        },

        setPayloadData: (state, payload) => {
            state.payloadData = payload;
            localStorage.setItem("payloadData", JSON.stringify(payload));
        }
    },

    actions: {
        updateDetailedData({ commit }, data) {
          commit("setDetailedData", data);
        },

        updatePayloadData({ commit }, payload) {
            commit("setPayloadData", payload);
        }
    },
    getters: {
      getDetailedData: (state) => state.detailedData,
      getPayloadData: (state) => state.payloadData
    }
});

