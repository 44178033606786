<template>
  <div>
    <b-row class="m-0">
      <!-- <b-col cols="11" class="text-left m-0 p-0">
        <b-form-tags
          style="border: 0px"
          v-if="opTag.length"
          class="p-0 m-0 text-right"
          v-model="opTag"
          placeholder=""
          no-outer-focus
        >
          <template v-slot="{ tags, inputAttrs, inputHandlers, addTag }">
            <div>
              <b-form-tag
                class="m-1"
                v-for="tag in tags"
                @remove="removeOpTag(tag)"
                :key="tag"
                :title="tag"
                variant="primary"
                >{{ tag }}</b-form-tag
              >
            </div>
          </template>
        </b-form-tags>
      </b-col> -->
      <b-col cols="1" class="m-0 p-0">
        

        <div>
          <p style="" class="search-icon m-0" v-b-modal.modal-1>
            <b
              ><strong
                ><feather type="filter" style="color: white"></feather
              ></strong>
            </b>
          </p>
        </div>
      </b-col>
    </b-row>
    <b-modal hide-footer id="modal-1" title="Filters">
      <b-row class="m-5">
        Operators
        <multiselect
          placeholder="Select Operators"
          :multiple="true"
          :close-on-select="true"
          track-by="netTypeId"
          label="value"
          v-model="selectedOps"
          :options="operatorOpts"
        ></multiselect>
      </b-row>
      
      <b-button class="mt-5" variant="primary" block @click="applyFilter"
        >Apply Filter</b-button
      >
    </b-modal>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import globalLit from "../../../services/globalLit";
import NetworkTypeService from "../../../services/NetworkTypeService";
export default {
  name: "FilterView",
  components: {
    Multiselect,
  },
  props: {
    isFiltred: Boolean,
  },
  data() {
    return {
      operatorOpts: [],
      selectedOps: [],
      spOpts: [],
      selectedSps: [],
      opTag: [],
      catTag: [],
      selectedCats: [],
      categoryOpts: [],
    };
  },
  methods: {
    removeOpTag(tag) {
      this.selectedOps = [];
      let index = this.opTag.indexOf(tag);
      this.opTag.splice(index, 1);
      tag = tag.split(":")[0].trim().toLowerCase();

      if (tag === "categories") {
        localStorage.removeItem("category");
        this.selectedCats = [];
      }
      if (tag === "operators") {
        localStorage.removeItem("operator");
        this.selectedOps = [];
      }
      location.reload();
    },
    async init() {
      await this.getOperators();
      await this.getCategories();
    },
    async applyFilter() {
      this.$bvModal.hide("modal-1");
      // store country in loal storage and reload page
      if (this.selectedOps.length > 0) {
        localStorage.setItem("operator", JSON.stringify(this.selectedOps));
      } else {
        localStorage.removeItem("operator");
      }
      if (this.selectedCats.length > 0) {
        localStorage.setItem("category", JSON.stringify(this.selectedCats));
      } else {
        localStorage.removeItem("category");
      }

      this.updateSelectedOperators(
        JSON.parse(localStorage.getItem("operator") || "[]")
      );
      location.reload();
    },
    async getCSPs() {
      try {
        let country = localStorage.getItem("country");
        const response = await NetworkTypeService.getAllCSPs(country);
        if (response.result) {
          this.spOpts = Object.keys(response.data).map((item) => {
            return {
              spid: item,
              value: response.data[item],
            };
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getCategories() {
      let category = localStorage.getItem("category");
      this.catTag = [];
      if (category) {
        this.selectedCats = JSON.parse(category);
        this.opTag.push(
          `Categories: ${this.selectedCats.map((e) => e.name).join(",")}`
        );
      }
      this.categoryOpts = globalLit.categorylist;
    },
    async getOperators() {
      let operator = localStorage.getItem("operator");
      if (operator) {
        this.selectedOps = JSON.parse(operator);
        this.opTag.push(
          `Operators: ${this.selectedOps.map((e) => e.value).join(",")}`
        );
      }
      try {
        let country = localStorage.getItem("country");
        const response = await NetworkTypeService.getAllNetworkType(country);
        if (response.result) {
          this.operatorOpts = Object.keys(response.data).map((item) => {
            return {
              netTypeId: item,
              value: response.data[item],
            };
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    updateSelectedOperators(updatedOperators) {
      this.selectedOps = updatedOperators;

      // Optionally update the display tag (if you use opTag to show current operators)
      const opTagIndex = this.opTag.findIndex((t) =>
        t.startsWith("Operators:")
      );
      if (updatedOperators.length > 0) {
        const tagStr = `Operators: ${updatedOperators
          .map((e) => e.value)
          .join(",")}`;
        if (opTagIndex !== -1) {
          this.opTag.splice(opTagIndex, 1, tagStr);
        } else {
          this.opTag.push(tagStr);
        }
      } else {
        if (opTagIndex !== -1) {
          this.opTag.splice(opTagIndex, 1);
        }
      }
    },
  },
  // mounted() {
  //   this.init();
  // },

  mounted() {
    this.init();
    // Listen for the operator-updated event from subheader
    this.$root.$on("operator-updated", this.updateSelectedOperators);
  },
  beforeDestroy() {
    this.$root.$off("operator-updated", this.updateSelectedOperators);
  },
};
</script>
<style>
.search-icon {
  border-radius: 100%;
  background-color: #274aa6;

  width: 36px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  position: relative;
}
</style>
