import api from './api'

const userInfo = JSON.parse(localStorage.getItem('userinfo') || '{}');

export default { 
    addNetworkType(data) {
        // return api.execute(`post`,`/moderator/nettype`, data)
        return api.execute(`post`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/nettype`, data);
        
    },
    updateNetworkType(data) {
        // return api.execute(`put`,`/moderator/nettype`, data)
        return api.execute(`put`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/nettype`, data);

    },
    getNetworkType() {
        // return api.execute('get', `/moderator/nettype`)
        return api.execute(`get`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/nettype`);

    },
    removeNetworkType(data) {
        // return api.execute('delete', `/moderator/nettype`,data)
        return api.execute(`delete`, `${userInfo.role === "tester" ? "/tester" : "/moderator"}/nettype`, data);

    },
    getAllNetworkType(country) {
        let url = `/nettype`
        if(country == 'undefined'){
            url = `/nettype`
        } else if(country){
            url = `/nettype?country=${country}`
        }
        return api.execute('get', url)
    },
    getCountry(){
        return api.execute('get', `/country`)
    }

}