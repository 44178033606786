<template>
      <div>
        <div id="canvas-bookmark" class="page-wrapper" :class="layout.settings.sidebar.type">
          <Header />
          <div class="page-body-wrapper" :class="layout.settings.sidebar.body_type">
            <div class="page-sidebar" :class="[{ open : !togglesidebar }, transparentbg ? '' : layout.settings.sidebar_backround ]" :sidebar-layout="layout.settings.sidebar_setting">
              <Sidebar />
            </div>
            <RightSidebar :rightsidebar_toggle_var = rightsidebar_toggle_var />
            <div class="page-body">
              <transition name="fadeIn" enter-active-class="animated fadeIn">
                <router-view class="view"></router-view>
              </transition>
            </div>
            <Footer/>
          </div>
          <!-- <Customizer/> -->
        </div>
      </div>
</template>

<script>
import { mapState } from "vuex";
import Header from "./header";
import Sidebar from "./sidebar";
import RightSidebar from "./right_sidebar";
import Footer from "./footer";
import Customizer from "./customizer";

export default {
  name: "mainpage",
  data() {
    return {
      rightsidebar_toggle_var: false,
      mobileheader_toggle_var: false,
      sidebar_toggle_var: true,
      horizontal_Sidebar: true,
      resized: false,
    };
  },
  // props:['sidebar_toggle_var'],
  components: {
    Header,
    Sidebar,
    RightSidebar,
    Footer,
    Customizer,
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.data,
      layout: (state) => state.layout.layout,
      transparentbg: (state) => state.layout.transparentbg,
      togglesidebar: (state) => state.menu.togglesidebar,
    }),
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.resized = this.sidebar_toggle_var;
    this.$store.dispatch("layout/set");
  },
  watch: {
    $route() {
      this.menuItems.filter((items) => {
        if (items.path === this.$route.path)
          this.$store.dispatch("menu/setActiveRoute", items);
        if (!items.children) return false;
        items.children.filter((subItems) => {
          if (subItems.path === this.$route.path)
            this.$store.dispatch("menu/setActiveRoute", subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === this.$route.path)
              this.$store.dispatch("menu/setActiveRoute", subSubItems);
          });
        });
      });
    },
    sidebar_toggle_var: function () {
      this.resized =
        this.width <= 991 ? !this.sidebar_toggle_var : this.sidebar_toggle_var;
    },
  },
  methods: {
    sidebar_toggle(value) {
      this.sidebar_toggle_var = !value;
    },
    rightsidebar_toggle(value) {
      this.rightsidebar_toggle_var = value;
    },
    mobiletoggle_toggle(value) {
      this.mobileheader_toggle_var = value;
    },
    handleResize() {
      this.$store.dispatch("menu/resizetoggle");
    },
  },
};
</script>

<style lang="scss" scoped></style>
