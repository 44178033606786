<template >

<div>
  <div>
      <sub-filter></sub-filter>
    </div>
 
</div>
</template>
<script>
import Bookmark from  './bookmark'
import SubFilter from './SubFilter.vue'
export default {
  data(){
    return{
      dataAvailable: true,
      user_role:'',
    } 
  },
  mounted() {
    let user = JSON.parse(window.localStorage.getItem("userinfo"));
    this.user_role = user.role;
  },
  props: {
    title: {
      default: "home",
    },
    main: {
      default: "",
    },
    subtitle: {
      default: ''
    },
    display: {
      default: false
    },
  },
  components:{
    Bookmark,
    SubFilter
  },
  methods: {},
};
</script>
<style scoped>
.showBreadcrumbs {
  display: none !important;
}
</style>
