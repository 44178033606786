<template>
  <div>
    <b-row :class="{'heightChange': allLocalStorageData.length}">
      <b-col class="test">
        <b-form-tags
          style="border: 0px"
          v-if="allLocalStorageData.length"
          class="p-0 m-0 text-left"
          v-model="allLocalStorageData"
          placeholder=""
          no-outer-focus
        >
          <template v-slot="{ tags }">
            <div>
              <b-form-tag
                v-for="tag in tags"
                @remove="removeOpTag(tag)"
                :key="tag"
                :title="tag"
                :style="{
                  background:
                    'linear-gradient(to right, #1977bf 0%, #1977bf 100%)',
                }"
                >{{ tag }}</b-form-tag
              >
            </div>
          </template>
        </b-form-tags>
      </b-col>
    </b-row>
  </div>
</template>
<script>
var body = document.getElementsByTagName("body")[0];
import Multiselect from "vue-multiselect";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import serviceApi from "../../services/ServiceInfo";
import FilterView from "../views/Dashboard/FilterView.vue";
export default {
  components: { Multiselect, DateRangePicker, FilterView },
  data() {
    return {
      allLocalStorageData: [],
    };
  },
  mounted() {
    this.fetchDataFromLocalStorage();
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    this.user_role = this.user.role;
    this.countryArray = [];
    this.user.countryArray.map((item, index) => {
      this.countryArray.push(item);
    });
    if (localStorage.getItem("country")) {
      this.selectedCountry = localStorage.getItem("country");
    } else {
      this.selectedCountry = this.user.countryArray[0].id;
      this.StoreCountry();
    }
    this.init();
    document.addEventListener("click", this.closeDropdown);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeDropdown);
  },
  methods: {
    async init() {
      // Fetch data from the API
      this.isBusy = true;
      let payload = {};
      try {
        let response = await serviceApi.getMonthlyServices(payload);
        if (response.result) {
          this.currentMonth = response.data.cMonth;
          this.lastMonth = response.data.lMonth;
          const serData = response.data.servicesList;
          const filteredSerData = serData.filter(
            (e) => this.currentMonth[e._id] || this.lastMonth[e._id]
          );
          this.itemList = filteredSerData; // Set itemList
          this.filteredList = [...this.itemList]; // Initialize filteredList
        }
      } catch (e) {
        console.error("Error in fetching monthly services:", e.message);
      } finally {
        this.isBusy = false;
      }
    },
    StoreCountry() {
      // store country in loal storage and reload page
      localStorage.setItem("country", this.selectedCountry);
      location.reload();
    },
    fetchDataFromLocalStorage() {
      console.log("localstorage", localStorage);
      //   const country = localStorage.getItem("country");
      const allOperators = JSON.parse(localStorage.getItem("operator"));
      //   console.log("country", country);
      console.log("allOperators", allOperators);
      //   this.allLocalStorageData.push(country);
      allOperators.map((operator) => {
        return this.allLocalStorageData.push(operator.value);
      });
      console.log("allLocalStorageData", this.allLocalStorageData);
    },
    removeOpTag(tag) {
      let index = this.allLocalStorageData.indexOf(tag);
      if (index > -1) {
        this.allLocalStorageData.splice(index, 1);
      }
      const normalizedTag = tag.trim().toLowerCase();
      const country = localStorage.getItem("country")?.trim().toLowerCase();
      if (normalizedTag === country) {
        localStorage.removeItem("country");
      } else {
        let allOperators = JSON.parse(localStorage.getItem("operator")) || [];
        allOperators = allOperators.filter(
          (operator) => operator.value.trim().toLowerCase() !== normalizedTag
        );
        if (allOperators.length) {
          localStorage.setItem("operator", JSON.stringify(allOperators));
        } else {
          localStorage.removeItem("operator");
          // location.reload();
        }
        const updatedOperators = JSON.parse(
          localStorage.getItem("operator") || "[]"
        );
        this.$root.$emit("operator-updated", updatedOperators);
      }

      location.reload();
    },
  },
};
</script>
<style scoped>
.heightChange {
  height: 45px;
}
.test {
  
  position: fixed;
  z-index: 7;
  width: calc(100% - 255px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  -webkit-transition: 0.5s;
  transition: 0.5s; 
  }
/* .page-main-header .main-header-right .nav-right > ul > li:before { */
/* background: none; */
/* } */
.subfilter-class1 {
  margin-top: 130px;
  margin-left: 300px;
  width: 600px;
}
/* .page-main-header .main-header-right .nav-right .profile-dropdown ul {
  width: unset !important;
} */
@media screen and (min-device-width: 1200px) and (max-device-width: 1366px) {
  .subfilter-class1 {
    display: block !important;
    padding-left: 10px;
  }
}
</style>
