import api from './api'
export default {
  // allUsersList() {
  //   return api.execute('get', `/manager/users`)
  // },


  allUsersList(options = {}) {
    const page = options.page || 1; // Default page 1
    const limit = options.limit || 10; // Default limit 10
    
    // Build query string with page and limit
    const query = `?page=${page}&limit=${limit}`;
    
    return api.execute('get', `/manager/users${query}`);
  },


  updateUser(data) {
    return api.execute('put', '/manager/users', data)
  },
  sendForgotPasswordMail(data) {
    return api.execute('post', '/forgetpwd', data)
  },
  changePassword(data) {
    return api.execute('post', '/changepwd', data)
  },
  authenticate(){
    return api.execute(`get`,`/alive`)
  },
}