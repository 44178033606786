<template>
  <div id="app">
    <!-- Loader starts-->
    <div class="loader-wrapper" :class="{'loderhide' : !show }">
      <div class="loader loader-7">
        <div class="line line1"></div>
        <div class="line line2"></div>
        <div class="line line3"></div>
      </div>
    </div>
    <!-- Loader ends-->

    <!--<Main/>-->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      show: true
    };
  },
  mounted() {
    this.timeOut();
  },
  methods: {
    timeOut() {
      // this.basketAddSuccess = true;
      var self = this;
      setTimeout(function() {
        self.show = false;
      }, 1000);
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.loader-wrapper.loderhide {
  display: none;
}
</style>

<style>
.link_txt{
    color: blue;
    cursor: pointer;
}
tr{
  box-shadow: 0px 0px 1px 3px rgba(239, 238, 238, 0);
}
@media (max-width: 767.98px){
  tr{
    box-shadow: 0px 0px 1px 3px rgb(93, 92, 92);
  }
}

th{
    text-align: unset !important;
}
.google_btn{
  cursor: pointer;
  border:1px solid rgb(88, 88, 245);
  border-radius: 5px;
}
.google_btn:hover{
  border:2px solid rgb(0, 0, 255);
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header {
  font-size: 1vw !important;
  font-weight: normal !important;
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header svg {
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px !important;
}

.page-wrapper .page-body-wrapper .page-header .row h3 {
  font-size: 1.5rem !important;
}

.page-wrapper .page-body-wrapper .page-header .row h3 span {
  font-size: 1.1rem !important;
}

.custom-modal-card {
  box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.18) !important;
  border-radius: 10px !important;
  width: 50%;
}

.custom-modal-card-2 {
  box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.18) !important;
  border-radius: 10px !important;
}

.cust_text {
    display: block;
    max-width: 12em;
    order: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
.cust_text:hover {
    max-width: auto;
    text-overflow: clip;
    white-space: normal;
}

.cust_url {
    display: block;
    max-width: 10em;
    order: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #487afa;
    cursor: pointer;
}
.cust_url:hover {
    color: #163b97;
    text-decoration: underline;
    max-width: auto;
    text-overflow: clip;
    white-space: normal;
}

.custom-pagination {
  display: inline-block;
}

.custom-pagination a {
  color: #274AA6 !important;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 16px;
}

.custom-pagination a.active {
  background-color: #274AA6;
  color: white !important;
  border: 1px solid #274AA6;
}

.custom-pagination a:hover:not(.active) {background-color: #ddd; cursor: pointer;}

.pagination {
  justify-content: end;
}

  /* Hide the arrow for month change in the date range picker */
.daterangepicker .drp-calendar .prev,
.daterangepicker .drp-calendar .next {
  background-color: #ffffff !important;
}
.daterangepicker.ltr .ranges li {
    color: #000000 !important;
    font-size: 14px !important;
}

.daterangepicker.ltr .ranges li:hover {
    color: #ffffff !important;
}
.daterangepicker.ltr tr td.active {
  background-color: #007bff !important;
}
.daterangepicker td.in-range {
  background-color: #edf9fd !important;
}
.page-wrapper .page-body-wrapper .page-sidebar.light-sidebar {
    background-color: #ffffff !important;
}

.page-wrapper .page-body-wrapper .page-sidebar .main-header-left {
  padding: 0rem 0rem !important;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a.active {
  background-color: rgb(241, 241, 241) !important;
  border-radius: 5%;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a:hover {
  color: #274AA6 !important;
}

.modal-xxl{
  max-width: 80% !important;
  
}

.breadcrumbHeader > .breadcrumb {
  background-color: #fff !important;
  margin-bottom: 0;
  padding: 0.75rem 0rem;
  margin: 0rem 0rem !important;
}

.page-main-header .main-header-right {
  padding: 0.5rem 0rem 0.5rem 1rem !important;
  border-radius: 10px !important;
  margin: 1rem 1rem !important;
}

.datatable-vue {
  margin: 0px 0px !important;
}

td .cust-table-field{
  display: block;
  width: 8em !important;
  text-wrap: wrap !important;
}

td.cust-table-field div{
  width: 6em !important;
  text-wrap: wrap !important;
}

.custom-table-style th, 
.custom-table-style td {
  padding: 0.75rem 0.2rem !important;
}

.custom-table-head .thead-light th {
  background-color: #DBE5FF !important;
  width: 37% !important;
  padding: 0.5rem !important;
}

.custom-table-head td {
  padding: 0.5rem !important;
}

.card .custom-shadow .card-header {
  padding: 0.7rem !important;
  border-radius: 10px !important;
}


@media screen and (max-width: 991px) {
  .breadcrumbHeader > .breadcrumb{
    display: none !important;
  }

  .page-main-header .main-header-right {
    padding: 0.5rem 1rem !important;
  }

  .page-wrapper .page-main-header .main-header-left .logo-wrapper {
    margin-left: 50px !important;
  } 
  
}

.search-bg{
  background-color: white !important;
  border-right: 0px !important;
}

.badge {
  font-size: 18px;
  font-weight: lighter !important;
  margin: 10px !important; 
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.badge-gray{
  color: white !important;
  background-color: #939393 !important;
}

.custom-btn{
  color: #ffffff !important;
  background-color: #274AA6 !important;
  font-size: 1.25vw !important;
  border-radius: 0.313vw !important;
  padding: 0.404rem 1.354rem !important;
}

.custom-btn:hover{
  background-color: #325ecf !important;
}

.text-blue {
    color: #274AA6 !important;
}

.custom-btn-2{
  background-color: #ffffff !important;
  color: #274AA6 !important;
  border: #274AA6 1px solid !important;
  font-size: 1.25vw !important;
  border-radius: 0.313vw !important;
  padding: 0.404rem 1.354rem !important;
}
.custom-btn-2:hover{
  background-color: #274AA6 !important;
  color: #ffffff !important;
}

.custom-badge{
  background-color: #274AA6 !important;
  color: #ffffff !important;
  font-weight: normal !important;
  padding: 0.5vw !important;
}

.custome-title{
  font-weight: 600 !important; 
  font-size: 1.670vw !important; 
  color: #274AA6;
}

.date-range-custom .form-control {
  /* font-size: 1.3vw !important; */
  font-size: 1.2vw !important;
}

.date-range-custom1 .form-control {
  /* font-size: 1.3vw !important; */
  font-size: 1.1vw !important;
}

.custom-font-16 {
  font-size: 0.85vw !important;
}

.custom-font-18,
.custom-font-18 > .custom-select  {
  font-size: 1vw !important;
}

.custom-font-20 {
  font-size: 1.1vw !important;
}

.custom-font-22 {
  font-size: 1.2vw !important;
}

.custom-font-24 {
  font-size: 1.25vw !important;
}

.custom-font-26 {
  font-size: 1.354vw !important;
}

.custom-font-30 {
  font-size: 1.4vw !important;
}

.multiselect {
  font-size: 1vw !important;
  border: 1px solid rgb(209, 209, 209);
  border-radius: 5px;
}

.multiselect .multiselect__input, 
.multiselect .multiselect__option,
.multiselect .multiselect__placeholder,
.multiselect .multiselect__tags-wrap,
.multiselect .multiselect__tags,
.multiselect .multiselect__content {
  font-size: 1vw !important;
}


.multiselect .multiselect__option--highlight {
  font-size: 1vw;
}

.multiselect__single {
  font-size: 1vw !important;
  padding: 0.48vh 0vw !important;
}

.multiselect__select {
  margin: 0.26vw;
}

.page-item.active .page-link{
  background-color: #274AA6 !important;
  color: #ffffff !important;
}
.page-link {
  color: #274AA6 !important;
}

.custom-modal-card .nav-item a {
  font-size: 1vw !important;
}

.custom-modal-card .nav-pills li .nav-link.active {
  font-weight: bold !important;
}

.user-table .table > tbody > tr > td {
  max-width: 18.229vw !important;
}

.vue-dropzone>.dz-preview .dz-details .dz-filename  {
    overflow: hidden;
    color: black !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}


.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  font-size: 12px;
}

.pagination a.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.pagination a:hover:not(.active) {background-color: #ddd; cursor: pointer;}


@media (max-width: 767px) {
  .custom-btn{
    font-size: 18px !important;
    padding: 0.2rem 0.3rem !important;
    border-radius: 0.2rem !important;
  }
  .custom-btn-2{
    font-size: 18px !important;
    padding: 0.2rem 0.3rem !important;
    border-radius: 0.2rem !important;
    margin-left: 0.5rem;
  }

  .custome-title{
    font-size: 1.2rem !important; 
  }

  .date-range-custom .form-control {
    font-size: 0.8rem !important;
  }

  .multiselect__single,
  .custom-font-16,
  .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header {
    font-size: 0.8rem !important;
  }

  .custom-font-18 {
    font-size: 1rem !important;
  }

  .custom-font-20,
  .custom-font-22 {
    font-size: 1.2rem !important;
  }

  .custom-font-24 {
    font-size: 1.35rem !important;
  }

  .custom-font-26,
  .custom-font-30 {
    font-size: 1.425rem !important;
  }

  .custom-modal-card .nav-item a {
    font-size: 1rem !important;
  }

  .btn-container{
    display: flex;

  } 

  .badge-container .badge {
    font-size: 16px;
  }

  .badge-container .p-2 {
    padding: 10px 4px !important;
  }

  .badge-container .badge span {
    font-size: 16px;
  }

  .media-container {
    margin-left: 1rem;
  }

  .multiselect,
  .multiselect .multiselect__input, 
  .multiselect .multiselect__option,
  .multiselect .multiselect__placeholder,
  .multiselect .multiselect__tags-wrap,
  .multiselect .multiselect__tags,
  .multiselect .multiselect__content {
    font-size: 16px !important;
  }


  .multiselect .multiselect__option--highlight {
    font-size: 16px;
  }
}
</style>
